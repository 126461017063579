import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducers from "./store";
import { composeWithDevTools } from "redux-devtools-extension";
import "./index.scss";
import App from "./App";
import { WalletConnectProviderHook } from "./hooks/walletLogin";

const store = createStore(reducers, composeWithDevTools());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WalletConnectProviderHook>
    <Provider store={store}>
      <App />
    </Provider>
  </WalletConnectProviderHook>
);
