import axios from "axios";
import { createContext, useEffect, useState } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
// import { ETHX } from '../data/contracts/constant'
import TokenContract from '../components/TokenContract.json';
import BoxContract from '../components/BoxContract.json';
import MBHandler from '../components/MBHandler.json';
import GSmainAbi from '../components/GSmainAbi.json'
import WalletConnectProvider from '@walletconnect/web3-provider'
export const MB_API = process.env.REACT_APP_MB_API;
export const GAME_API = process.env.REACT_APP_GAME_API;

export const WalletConnectContext = createContext();

export const parseOriginalDate = (date) => {
  const parsedDate = new Date(date).toString().split(" ");
  return `${parsedDate[1]} ${parsedDate[2]}, ${parsedDate[3]}`;
};

export const WalletConnectProviderHook = ({ children }) => {
  const [isWalletLogin, setWalletLogin] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [walletStatus, setWalletStatus] = useState("");

  const [textBox, settextBox] = useState("");
  const [textBoxError, setTextBoxError] = useState(false);
  const [textBoxSuccess, setTextBoxSuccess] = useState(false);

  const web3 = new Web3(Web3.givenProvider);
  const [boxBalance, setboxBalance] = useState(0);
  const [openButton, setopenButton] = useState(false);

  const [tokenBalance, settokenBalance] = useState(0);
  const [offChainBalance, setOffChainBalance] = useState(0);
  const [claimTime, setclaimTime] = useState(0)
  const [available, setavailable] = useState(false)

  const [requestID, setRequestID] = useState("")
  const [reward, setReward] = useState(null)

  const MBHandlerContract = new web3.eth.Contract(
    MBHandler.abi,
    process.env.REACT_APP_MBHANDLER_CONTRACT_ADDRESS,
  );

  async function getUserStats() {
    await axios.post(`${GAME_API}/ydfClaim/getUserStats`, {
      address: walletAddress,
    }).then((res) => {
      if (res.data?.status == 200) {
        setclaimTime(res.data?.userStats?.nextClaim * 1000); // converted to milliseconds
        setOffChainBalance(res.data?.userStats?.offChainBalance);
        setavailable(res.data?.userStats?.canClaim)
      }
    }).catch((err) => {
      console.log("Error while getting offchain userStats:", err)
    });
  };

  const tokenBalanceFunc = async () => {
    const Web3 = require('web3');
    const web3 = new Web3(Web3.givenProvider);

    const contract = await new web3.eth.Contract(
      TokenContract.abi,
      process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    );

    const balance = await contract.methods.balanceOf(walletAddress).call()

    settokenBalance(balance)
  }

  const boxBalanceFunc = async () => {
    const Web3 = require('web3');
    const web3 = new Web3(Web3.givenProvider);

    const contract = await new web3.eth.Contract(
      BoxContract.abi,
      process.env.REACT_APP_BOX_CONTRACT_ADDRESS,
    );

    const balance = await contract.methods.balanceOf(walletAddress, 1).call()

    setboxBalance(balance)
  }

  const buyBox = async (amount) => {
    setTextBoxError(false);
    setTextBoxSuccess(false);

    settextBox("Loading...");

    const YDFAcontract = await new web3.eth.Contract(
      TokenContract.abi,
      process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    );

    const allowance = await YDFAcontract.methods.allowance(walletAddress, process.env.REACT_APP_GS_STORAGE_CONTRACT_ADDRESS).call()
    if (allowance < 20 * amount) {
      settextBox("Giving Approval to Giftstore Contract (one-time Tx)...");
      await YDFAcontract.methods.approve(process.env.REACT_APP_GS_STORAGE_CONTRACT_ADDRESS, 1000000)
        .send({ from: walletAddress })
        .then(() => {
          setTextBoxError(false);
          setTextBoxSuccess(true);
          settextBox("Approval Granted !");
        })
        .catch((err) => {
          setTextBoxError(true);
          setTextBoxSuccess(false);
          console.log("GSbuy Error:", err)
          if (
            typeof err !== "undefined" &&
            typeof err.message !== "undefined" &&
            (err.message.includes("User denied") || err.message.includes("rejected"))
          ) {
            settextBox("You rejected the transaction !! Try again.");
          } else {
            settextBox("Something went wrong. Try again or contact Support.");
          };
          return;
        })
    }

    if (await YDFAcontract.methods.allowance(walletAddress, process.env.REACT_APP_GS_STORAGE_CONTRACT_ADDRESS).call() < 20 * amount) {
      setTextBoxError(true);
      setTextBoxSuccess(false);
      settextBox("Something went wrong. Try again or contact Support.");
      return;
    }
    setTextBoxError(false);
    setTextBoxSuccess(false);
    settextBox("Submitting BuyBoxes transaction ...");
    const GSMainContract = await new web3.eth.Contract(
      GSmainAbi,
      process.env.REACT_APP_GS_MAIN_CONTRACT_ADDRESS,
    );
    await GSMainContract.methods.buyItem(1, amount, 0, "0x").send({ from: walletAddress })
      .then(() => {
        tokenBalanceFunc();
        boxBalanceFunc();
        setTextBoxError(false);
        setTextBoxSuccess(true);
        settextBox(`Congrats. You bought ${amount} Box${amount > 1 ? "es" : ""} !`)
      })
      .catch((err) => {
        setTextBoxError(true);
        setTextBoxSuccess(false);
        console.log("GSbuy Error:", err)
        if (
          typeof err !== "undefined" &&
          typeof err.message !== "undefined" &&
          (err.message.includes("User denied") || err.message.includes("rejected"))
        ) {
          settextBox("You rejected the transaction !! Try again.");
        } else {
          settextBox("Something went wrong. Try again or contact Support.");
        };
      })
  }

  const openTheBox = async () => {

    setTextBoxError(false);
    setTextBoxSuccess(false);
    settextBox("Submitting Open MBox transaction ...");

    await MBHandlerContract.methods.openBox(0, 0, "0x")
      .send({ from: walletAddress })
      .then((res) => {
        let _requestID = String(res.events.BoxOpeningRequested.returnValues.requestId)
        setRequestID(_requestID);
        settextBox("Transaction confirmed! Awaiting ChainLink VRF Response...")

        let _provider = new ethers.providers.Web3Provider(window.ethereum);
        let MBhandler = new ethers.Contract(process.env.REACT_APP_MBHANDLER_CONTRACT_ADDRESS, MBHandler.abi, _provider);

        const _rewardfilter = MBhandler.filters.RewardDistributed(null, null, walletAddress)
        MBhandler.once(_rewardfilter, (eventRequestId, _pool, requester, ref, attempt) => { //"RewardDistributed"

          if (eventRequestId.toString() == _requestID) {

            setTextBoxError(false);
            setTextBoxSuccess(false);
            settextBox(`VRF Response acquired! Fetching reward info..`)

            // MB-API ITEM INFO
            axios.post(`${MB_API}/mysteryBox/getItemInfo`, {
              "ref": ref.toNumber(),
            }).then(async (res) => {
              setReward(res.data.item)
              settextBox(`We got it! Click Reveal to see you prize.`) //you won ${res.data.item.title}
              setTextBoxError(false);
              setTextBoxSuccess(true);
              setopenButton(true);

            }).catch((err) => {
              console.log("API error:", err)
              settextBox(`Something went wrong. Try again or contact Support.`)
              setTextBoxError(true);
              setTextBoxSuccess(false);
            })

          }
        })

        const _retryingfilter = MBhandler.filters.RetryingDistribution(null, null, walletAddress)
        MBhandler.on(_retryingfilter, (eventRequestId, _pool, requester, _newRequestID, attemp) => { //"RewardDistributed"
          if (requester.toString() == String(walletAddress)) {
            setRequestID(_newRequestID)
            _requestID = _newRequestID.toString()
            settextBox(`Gift was soldout! Retrying...`)
          }
        })

        const _failurefilter = MBhandler.filters.FailedDistribution(null, null, walletAddress)
        MBhandler.once(_failurefilter, (eventRequestId, _pool, requester, attempt) => { //"RewardDistributed"
          if (requester.toString() == String(walletAddress)) {

            setTextBoxError(true);
            setTextBoxSuccess(false);
            settextBox(`Reveal Failed. Your box was returned. Try again!`)

          }
        })

      })
      .catch((error) => console.log(error))
  }

  const openMysteryBox = async () => {

    setTextBoxError(false);
    setTextBoxSuccess(false);
    settextBox("Loading...");

    const Web3 = require('web3');
    const web3 = new Web3(Web3.givenProvider);

    const BoxesContract = await new web3.eth.Contract(
      BoxContract.abi,
      process.env.REACT_APP_BOX_CONTRACT_ADDRESS,
    );

    const isApprovedForAll = await BoxesContract.methods.isApprovedForAll(walletAddress, process.env.REACT_APP_MBSTORAGE_CONTRACT_ADDRESS).call()
    if (!isApprovedForAll) {
      settextBox("Giving Approval to MysteryBoxes Contract (one-time Tx)...");
      await BoxesContract.methods.setApprovalForAll(process.env.REACT_APP_MBSTORAGE_CONTRACT_ADDRESS, true)
        .send({ from: walletAddress })
        .then(() => {
          setTextBoxError(false);
          setTextBoxSuccess(true);
          settextBox("Approval Granted !");
          openTheBox()
        })
        .catch((err) => {
          setTextBoxError(true);
          setTextBoxSuccess(false);
          console.log("MB approval Error:", err)
          if (
            typeof err !== "undefined" &&
            typeof err.message !== "undefined" &&
            (err.message.includes("User denied") || err.message.includes("rejected"))
          ) {
            settextBox("You rejected the transaction !! Try again.");
          } else {
            settextBox("Something went wrong. Try again or contact Support.");
          };
        })
    } else {
      openTheBox();
    }
  }

  if (walletAddress) {
    tokenBalanceFunc()
    boxBalanceFunc()
    getUserStats()
  }

  const getNonce = async (address) => {
    const nonce = await axios.post(`${MB_API}/auth/getNonce`, {
      address: address,
    });
    return nonce.data.data.nonce;
  };

  const connectWalletThroughMetamask = async (setOpenConnectModal, setChainChangeModal) => {
    try {
      if (window.ethereum) {
        var address
        if (window.ethereum.providers) {
          address = await window.ethereum.providers
            .find((provider) => provider.isMetaMask)
            .request({ method: 'eth_requestAccounts' })
        } else {
          if (window.ethereum.isMetaMask) {
            address = await window.ethereum.request({
              method: 'eth_requestAccounts',
            })
          }
        }
        if (address && address.length > 0) {
          await web3.eth
            .getAccounts()
            .then(async (data) => {
              if (data.length > 0) {
                setWalletAddress(
                  data[0]
                )
                setWalletStatus(
                  data.status
                )
                setWalletLogin(true)
                setOpenConnectModal(false)
                if (window?.ethereum?.networkVersion != process.env.REACT_APP_CHAIN_ID) {
                  setChainChangeModal(true)
                }
              }
            })
            .catch((err) => {
              console.log('Wallet Error:', err)
            })
        } else {
        }
      } else {
      }
      // setConnectLoading(false)
    } catch (err) {
      console.log(err)
      // setOpenConnectModal(false);
    }
  }

  const connectWalletThrougConnectWallet = async (setOpenConnectModal, setChainChangeModal) => {
    try {
      const provider = new WalletConnectProvider({
        rpc: {
          1: 'https://cloudflare-eth.com',
          137: 'https://polygon-rpc.com',
          80001: 'https://matic-mumbai.chainstacklabs.com',
          // 100: 'https://dai.poa.network',
        },
        bridge: 'https://bridge.walletconnect.org',
      })

      await provider.enable()
      const web3 = new Web3(provider)

      await web3.eth
        .getAccounts()
        .then(async (data) => {
          if (data.length > 0) {
            setWalletAddress(data[0])
            setWalletStatus(data.status)
            setWalletLogin(true)
            setOpenConnectModal(false)
            if (window?.ethereum?.networkVersion != process.env.REACT_APP_CHAIN_ID) {
              setChainChangeModal(true);
            }
          }
        })
        .catch((err) => {
          setOpenConnectModal(false)
          console.log('Wallet Error:', err)
        })

    } catch (err) {
      console.log(err.toString())
      setOpenConnectModal(false)
    }

  }

  useEffect(() => {
    const checkMetaLogined = async () => {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", async (value) => {
          window.location.reload()
        });
      }
    };

    // window.onunload = () => {
    //     // Clear the local storage
    //     window.Storage.clear()
    // }

    checkMetaLogined();
  }, []);

  return (
    <WalletConnectContext.Provider
      value={{
        tokenBalance,
        boxBalance,
        walletAddress,
        isWalletLogin,
        walletStatus,
        openButton,
        setopenButton,
        openMysteryBox,
        textBox,
        settextBox,
        textBoxError,
        setTextBoxError,
        textBoxSuccess,
        setTextBoxSuccess,
        setopenButton,
        connectWalletThroughMetamask,
        connectWalletThrougConnectWallet,
        tokenBalanceFunc,
        boxBalanceFunc,
        offChainBalance,
        claimTime,
        setclaimTime,
        available,
        getUserStats,
        buyBox,
        reward
      }}
    >
      {children}
    </WalletConnectContext.Provider>
  );
};
