import Layout from "../../components/Layout";
import styles from "./GiftShop.module.scss";

const GiftShop = () => {
  return (
    <Layout>
      <div style={{
        color: '#ffffffb8',
        fontSize: '52px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }} className={styles.wrapper}>
        Coming Soon
      </div>
    </Layout>
  );
};

export default GiftShop;
