import { useState, useContext } from 'react'
import Layout from '../../components/Layout'
import Menu from '../../components/Menu'
import styles from './Dashboard.module.scss'
import CoinCollect from '../../components/CoinCollect'
import { WalletConnectContext } from "../../hooks/walletLogin";
import { Navigate } from "react-router-dom";

const Dashboard = () => {
    const [menuItemSelected, setMenuItemSelected] = useState('coin_collect')
    const { walletAddress } = useContext(WalletConnectContext);

    function handleMenuItemSelect(item) {
        setMenuItemSelected(item)
    }

    if (!walletAddress) {
        return <Navigate to='/' />
    }

    return (
        <Layout
            handleMenuItemSelect={handleMenuItemSelect}
            menuItemSelected={menuItemSelected}
        >
            <>
                <div className={styles.wrapper}>
                    <Menu
                        menuItemSelected={menuItemSelected}
                        handleMenuItemSelect={handleMenuItemSelect}
                        menuFor={'dashboard'}
                    />
                    {menuItemSelected === 'coin_collect' ? (
                        <CoinCollect />
                    ) : (
                        // <CardList
                        //     listFor={'dashboard'}
                        //     menuItemSelected={menuItemSelected}
                        //     metadata={metadata}
                        // />
                        <></>
                    )}
                </div>
            </>
        </Layout>
    )
}

export default Dashboard
