import FireImg from "../../assets/TimerBar/fire.svg";
import { useCountdown } from "../../hooks/countdown";
import styles from "./TimerBar.module.scss";

const TimerBar = ({ type, timer, ...props }) => {
  const [days, hours, minutes, seconds] = useCountdown(timer);

  return (
    <div
      className={`${styles.timerBar} ${
        type == "raffle" ? styles.timerBarR : styles.timerBarW
      }`}
      style={props.styles}
    >
      <img src={FireImg} alt="fire" />
      <p
        className={`${styles.timerValue} ${
          type == "raffle" ? styles.timerValueR : styles.timerValueW
        }`}
      >
        {days + hours + minutes + seconds <= 0
          ? "Expired"
          : `${days}:${hours}:${minutes}:${seconds}`}
      </p>
    </div>
  );
};

export default TimerBar;
