import React from 'react'
import Header from '../Header'
import styles from './Layout.module.scss'

const Layout = ({ children, handleMenuItemSelect, menuItemSelected }) => {
    return (
        <div className={styles.main}>
            <Header
                handleMenuItemSelect={handleMenuItemSelect}
                menuItemSelected={menuItemSelected}
            />
            {children}
        </div>
    )
}

export default Layout
