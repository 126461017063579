import { NavLink } from "react-router-dom";
import styles from "./MysteryBoxFooter.module.scss";
import twitter from "../../assets/MysteryBox/twitter.svg";
import discord from "../../assets/MysteryBox/discord.svg";
import medium from "../../assets/MysteryBox/medium.svg";
import instagram from "../../assets/MysteryBox/instagram.svg";
import opensea from "../../assets/MysteryBox/opensea.svg";

const MysteryBoxFooter = () => {
  return (
    <div className={styles.mysteryBoxFooter}>
      <a target="_blank" href="https://9tales.io">
        <img
          src="https://9tales.io/wp-content/uploads/2022/05/nine-tales-logo.svg"
          className={styles.logo}
          alt="logo"
        />
      </a>
      <div className={styles.routes}>
        <a target="_blank" href="https://9tales.io/alpha"
          // style={({ isActive }) =>
          //   isActive ? { color: "#FF1840" } : { color: "white" }
          // }
          className={styles.navlinks}
        >
          Try the Alpha !
        </a>

        <a target="_blank"
          href="https://9tales.io/9tales-alpha-launch-q-and-a"
          // style={({ isActive }) =>
          //   isActive ? { color: "#FF1840" } : { color: "white" }
          // }
          className={styles.navlinks}
        >
          Learn more
        </a>

        <a target="_blank"
          href="https://9tales.notion.site/9tales/9Tales-DAWN-Bug-Bounty-d720b4cc40444881986e9e2c71a054fe/"
          className={styles.navlinks}
        >
          Bug Bounty
        </a>

        <a
          target="_blank"
          href="https://9tales.medium.com/"
          className={styles.navlinks}
        >
          Blog
        </a>

      </div>
      <div className={styles.socialMedia}>
        <a target="_blank" href="https://twitter.com/9tales_NFT"><img src={twitter} alt="twitter" /></a>
        <a target="_blank" href="https://discord.com/invite/9talesNFT"><img src={discord} alt="discord" /></a>
        <a target="_blank" href="https://twitter.com/9tales_NFT"><img src={instagram} alt="instagram" /></a>
        <a target="_blank" href="https://9tales.medium.com/"><img src={medium} alt="medium" /></a>
        <a target="_blank" href="https://opensea.io/collection/9tales"><img src={opensea} alt="opensea" /></a>
      </div>
    </div>
  );
};

export default MysteryBoxFooter;
