import MysteryBoxHeader from "../../components/MysteryBoxHeader";
import styles from "./MysteryBox.module.scss";
import { Checkbox, Modal, useMediaQuery } from "@mui/material";
import PrizeCard from "../../components/Cards/PrizeCard";
import MysteryBoxFooter from "../../components/MysteryBoxFooter";
import { useContext, useEffect, useRef, useState } from "react";
import { getPoolInfo, getPoolItems } from "../../utils/api";
import stylus from "../../components/Cards/CardModal/CardModal.module.scss";
import axios from "axios";
import { MB_API } from "../../hooks/walletLogin";
import ConnectModal from "../../components/Modals/ConnectModal";
import ChangeChainModal from "../../components/Modals/ChangeChainModal";
import { WalletConnectContext } from "../../hooks/walletLogin";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import $ from 'jquery'

const ClosedCrateModal = ({ modalVisible, setModalVisible, setOpenCrate }) => {
  const [loader, showLoader] = useState(false);
  const {
    openButton,
    textBox,
    settextBox,
    textBoxError,
    setTextBoxError,
    textBoxSuccess,
    setTextBoxSuccess,
    setopenButton,
  } = useContext(WalletConnectContext);

  $(document).ready(() => {
    $('#get9TalesButton').click(() => {
      setModalVisible(false);
      let openChestAudio = new Audio('open-chest-audio.aac')
      openChestAudio.volume = 0.5
      openChestAudio.play()
      $('#mainModal').removeClass('d-block')
      setOpenCrate(true)
      setopenButton(false)
    })
  })

  const style = {
    background: "rgba(0,0,0,0.4)",
    width: "100vw",
    height: "100vh",
    backdropFilter: "blur(10px)",
  };
  return (
    <Modal
      open={modalVisible}
      onClose={() => {
        setTextBoxError(false);
        setTextBoxSuccess(false);
        setModalVisible(false);
        showLoader(false);
      }}
      BackdropProps={{ style: style }}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className={styles.crateModalContainer}>
        <img
          style={{ position: 'absolute', width: '60%', height: '60%', top: '14%' }}
          id='chestImg'
          src='https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669481130/only_box.webp'
          className={(!textBoxSuccess && !textBoxError) ? 'chest-box zoom-in dark' : ''}
        />
        <img
          src={
            "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669480719/1_without_crate_and_open_button.webp"
          }
          className={styles.crateImg}
          alt="crate box"
        />
        <p style={{
          color: textBoxError ? 'red' : (textBoxSuccess ? 'green' : 'gold'),
          position: 'absolute',
          bottom: '12.5%',
          fontFamily: "Lato",
          fontSize: '16px',
        }}>{textBox}</p>
        {openButton && (<div
          className={styles.crateButton}
          onClick={() => {
            // setModalVisible(false);
            // setOpenCrate(true);
            // showLoader(true);
          }}
        >
          {loader ? (
            <button
              className={`${styles.loader_1} ${styles.box} ${styles.button}`}
            ></button>
          ) : (
            <p id="get9TalesButton" style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>Reveal</p>
          )}
        </div>)}
      </div>
    </Modal>
  );
};

const BuyCrateModal = ({ modalVisible, setBuyCrate, setModalVisible }) => {
  const {
    tokenBalance,
    boxBalance,
    buyBox,
    tokenBalanceFunc,
    boxBalanceFunc,
    setTextBoxError,
    setTextBoxSuccess,
  } = useContext(WalletConnectContext);
  const [totalBoxes, settotalBoxes] = useState(1);

  const style = {
    background: "rgba(0,0,0,0.4)",
    width: "100vw",
    height: "100vh",
    backdropFilter: "blur(10px)",
  };
  return (
    <Modal
      open={modalVisible}
      onClose={() => {
        setBuyCrate(false);
        tokenBalanceFunc();
        boxBalanceFunc();
        setTextBoxError(false);
        setTextBoxSuccess(false);
      }}
      BackdropProps={{ style: style }}
      style={{
        display: "flex",
      }}
    >
      <div className={styles.crateModalContainer}>
        <img
          src={
            "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1667763709/keyOpenCrate.webp"
          }
          className={styles.crateImg}
          alt="crate box"
        />
        <div className={styles.buyCrateBtnsCont}>
          <img
            src={
              "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1667819767/crateBtns.webp"
            }
            className={styles.crateBtns}
          />
          {/* <div className={styles.boxesTokensCount}>
            <p>{boxBalance}</p>
            <p>{tokenBalance}</p>
          </div> */}
        </div>
        <div
          style={{ marginLeft: "20px", fontFamily: "Montserrat" }}
          className={styles.buyCrateStats}
        >
          <p style={{ marginLeft: "15px", }}>{totalBoxes} </p>
          <p style={{ marginLeft: "25px", }}>Box{totalBoxes > 1 ? "es" : ""}</p>
          <p style={{ marginLeft: "35px", }}> {totalBoxes * 20} YDF</p>
        </div>
        <div className={styles.buyCrateBuyBtns}>
          <div
            className={styles.controls}
            onClick={() => {
              if (totalBoxes <= 1) {
                settotalBoxes(1);
              } else {
                settotalBoxes((prev) => prev - 1);
              }
            }}
          ></div>
          <div
            className={styles.buy}
            onClick={() => {
              buyBox(totalBoxes)
              setBuyCrate(false)
              setModalVisible(true)

            }}
          ></div>
          <div
            className={styles.controls}
            onClick={() => {
              if (totalBoxes < 10 && (totalBoxes + 1) * 20 <= tokenBalance) {
                settotalBoxes((prev) => prev + 1);
              }
            }}
          ></div>
        </div>
      </div>
    </Modal>

  );
};

const OpenedCrateModal = ({ modalVisible, setModalVisible }) => {

  const {
    // textBox,
    // settextBox,
    // textBoxError,
    // textBoxSuccess,
    reward,
  } = useContext(WalletConnectContext);

  const style = {
    background: "rgba(0,0,0,0.4)",
    width: "100vw",
    height: "100vh",
    backdropFilter: "blur(10px)",
  };

  return (
    <Modal
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      BackdropProps={{ style: style }}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className={styles.crateModalContainer}>
        <img
          src={
            "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669480719/1_without_crate_and_open_button.webp"
          }
          className={styles.openedCrateImg}
          alt="crate box"
        />
        <img src="https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_500/v1669493950/Group_34554_tgvils.webp" style={{
          position: 'absolute',
          width: '80%',
          height: '75%',
          top: '12%'
        }} />
        <img src={(reward && reward?.itemImageUrl) ? reward.itemImageUrl : ""} style={{ position: 'absolute', top: '40%', width: '25%' }} />
        {/* <div className={styles.crateDescription}>
          Sit esse Lorem tempor ea labore sunt id consequat occaecat veniam ut.
          Ea officia ea sunt fugiat exercitation fugiat consequat elit commodo
          aute. Aliqua cupidatat sit exercitation eu voluptate cupidatat.
        </div> */}
        <p className="textBox" style={{
          color: 'green',
          position: 'absolute',
          bottom: '12.5%',
          fontFamily: "Lato",
          fontSize: '16px',
        }}> Congratulations! You won {reward?.title || 'Nothing'} </p>
      </div>
    </Modal>
  );
};

const MysteryBox = () => {
  const {
    isWalletLogin,
    connectWalletThroughMetamask,
    connectWalletThrougConnectWallet,
    // connectWalletThroughTrustWallet,
    walletAddress,
    connectLoading,
    openModal,
    setOpenModal,
    tokenBalance,
    boxBalance,
    openMysteryBox,
    settextBox,
    setTextBoxError,
    setTextBoxSuccess,
  } = useContext(WalletConnectContext);

  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [changeChainModal, setChainChangeModal] = useState(false);

  const connectWallet = async (wallet) => {
    if (wallet == "metamask") {
      await connectWalletThroughMetamask(setOpenConnectModal, setChainChangeModal);
    } else if (wallet == "connectWallet") {
      await connectWalletThrougConnectWallet(setOpenConnectModal, setChainChangeModal);
    }
    return;
  };
  const isMobile = useMediaQuery("(max-width: 420px)");
  const isLaptop = useMediaQuery("(max-width: 1024px)");
  const [poolInfo, setPoolInfo] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [openCrate, setOpenCrate] = useState(false);
  const [buyCrate, setBuyCrate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const ref = useRef(null);

  const [filters, setFilters] = useState({
    pool: 0,
    category: "All",
    newestFirst: false,
    available: false,
    includeExpired: false,
    length: 20,
  });
  const [poolItems, setPoolItems] = useState();
  const [showCardModal, setShowCardModal] = useState(false);
  const [dataItem, setdataItem] = useState();
  const [Search, setSearch] = useState("");
  const [skip, setskip] = useState(0);

  useEffect(() => {
    if (showCardModal) {
      axios
        .post(`${MB_API}/mysteryBox/getItemInfo`, { ref: dataItem.ref })
        .then((res) => {
          setdataItem(res.data.item);
        });
    }
  }, [showCardModal]);

  useEffect(() => {
    async function getPool() {
      const response = await getPoolInfo(0);
      if (response) {
        setPoolInfo(response?.data.pool);
      }
    }

    getPool();
  }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchPoolItems() {
      const response = await getPoolItems(filters);
      if (response) {
        setPoolItems(
          response?.data?.items
            .filter((item) => {
              if (
                item.category == filters.category ||
                filters.category == "All"
              ) {
                return true;
              } else {
                return false;
              }
            })
            .filter((item) => {
              if (item.title.toLowerCase().includes(Search) || Search == '') {
                return true;
              } else {
                return false;
              }
            })
            .sort((a, b) => {
              const aa = new Date(a.createdAt).getTime();
              const bb = new Date(b.createdAt).getTime();
              if (filters.newestFirst) {
                return bb - aa;
              } else {
                return aa - bb;
              }
            })
        );
        setLoading(false);
      }
      setPageLoading(false)
    }
    fetchPoolItems();
  }, [filters, skip, Search]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', (chain) => {
        if (parseInt(chain) != process.env.REACT_APP_CHAIN_ID) {
          setChainChangeModal(true)
        } else {
          setChainChangeModal(false)
        }
      });
    }

  }, [walletAddress])

  const style = {
    background: `rgba(7,2,4,0.8)`,
    backgroundRepeat: "no-repeat",
    filter: "blur(15px)",
    width: "100vw",
    height: "100vh",
  };

  const prizePoolOptions = [
    {
      img: "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667866905/nftIcon.webp",
      value: poolInfo?.onChainData?.totalSupply,
      label: "NIT Alpha Cards",
    },
    // {
    //   img: "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667866935/giftBox.webp",
    //   value: poolInfo?.onChainData?.totalSupply,
    //   label: "Gifts",
    // },
    // {
    //   img: "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667866893/PS5.webp",
    //   value: "",
    //   label: "Soon",
    // },
    // {
    //   img: "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667866910/money.webp",
    //   value: "",
    //   label: "Soon",
    // },
    // {
    //   img: "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667866905/nftIcon.webp",
    //   value: "poolInfo?.nftCount",
    //   label: "Soon",
    // },
  ];

  const classes = {
    BgClass: "standardBg",
    BtClass: "standardBt",
    DcRoles: "standardRoles",
  };

  const LiveFeedComponent = ({ label, time, prize }) => {
    const prizeColors = {
      discord: "#8572F9",
      playStation: "#DAF33C",
      netflix: "#F93C3C",
    };

    return (
      <div className={styles.liveFeedComponent}>
        <div>
          <p className={styles.label}>{label}</p>
          <p className={styles.time}>{time}</p>
        </div>
        <p className={styles.prize} style={prizeColors.prize}>
          {prize}
        </p>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      {pageLoading ? (
        <div className={styles.loaderContainer}>
          {/* <div className={styles.spinner}></div> */}
          <button
            className={`${styles.loader} ${styles.box} ${styles.button}`}
            style={{
              color: "red",
              position: "relative",
              left: "0.5%",
              top: "0%",
            }}
          ></button>
        </div>
      ) : (
        <>
          <div className={styles.mainHeader}>
            <MysteryBoxHeader />
          </div>
          <div style={{ overflow: 'hidden' }} className={styles.bgCont}>
            <img className="backgroundStone" src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1668641476/stone_2_obl3h8.webp'} />
            <img
              src={
                isMobile
                  ? "https://res.cloudinary.com/foxledgerstudio/image/upload/g_xy_center,q_auto:best/v1668447495/mobileBg.webp"
                  : "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1667759594/buysell.webp"
              }
              alt="Bg Img"
              className={styles.bgImg}
            />
            <div className={styles.crateBtnsCont}>
              <img
                src={
                  "https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1667819767/crateBtns.webp"
                }
                className={styles.crateBtns}
              />
            </div>
            <ClosedCrateModal
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              setOpenCrate={setOpenCrate}
            />
            <BuyCrateModal
              modalVisible={buyCrate}
              setModalVisible={setModalVisible}
              setBuyCrate={setBuyCrate}
            />
            <OpenedCrateModal
              modalVisible={openCrate}
              setModalVisible={setOpenCrate}
            />
            <div
              className={styles.crateHover}
              onClick={async () => {
                if (!isWalletLogin) {
                  setOpenConnectModal(true);
                } else {
                  try {
                    if (window?.ethereum?.networkVersion != process.env.REACT_APP_CHAIN_ID) {
                      setChainChangeModal(true);
                    } else {
                      if (tokenBalance < 20) {
                        setModalVisible(true);
                        settextBox("You don't have enough YDFA to buy a Box");
                        setTextBoxError(true)
                        setTextBoxSuccess(false)
                      } else {
                        setBuyCrate(true);
                      }
                    }
                    // settextBox("Mystery Boxes are Sold Out.");
                    // setTextBoxError(true)
                    // setTextBoxSuccess(false)
                    // setModalVisible(true);
                  } catch (err) {
                    console.log(err);
                  }
                }
              }}
            ></div>
            <div
              className={styles.keyHover}
              onClick={() => {
                if (!isWalletLogin) {
                  setOpenConnectModal(true);
                } else {
                  try {
                    if (window?.ethereum?.networkVersion != process.env.REACT_APP_CHAIN_ID) {
                      setChainChangeModal(true);
                    } else {
                      if (boxBalance < 1) {
                        setModalVisible(true);
                        settextBox("You don't have any Mystery Box to open !");
                        setTextBoxError(true)
                        setTextBoxSuccess(false)
                      } else {
                        setModalVisible(true);
                        openMysteryBox()
                      }
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }
              }}
            ></div>
            <div
              className={styles.bgText}
              onClick={() => {
                ref.current?.scrollIntoView({ behavior: "smooth" });
              }}
              style={!isMobile ? { opacity: "0" } : { opacity: "1" }}
            >
              <img style={{
                position: 'absolute',
                width: '260px',
                left: '-90px',
                transform: 'rotate(-14deg)',
                top: '-104px'
              }} src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1668641476/stone_2_obl3h8.webp'} />
            </div>
          </div>
          <div className={styles.prizesWrapper}>
            <div className={styles.prizePool} ref={ref}>
              <p className={styles.heading}>Prize Pool</p>
              <div className={styles.prizePoolComponentsCont}>
                {prizePoolOptions?.map((item) => {
                  return (
                    <div className={styles.prizePoolComponents}>
                      <img src={item.img} alt="prize" />
                      <p>
                        <b>{item.value}</b> {item.label}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.prizesInfo}>
              {/* {!isLaptop && ( */}
              <div className={styles.liveFeed}>
                <p
                  style={{ fontFamily: '"Rigamesh", sans-serif' }}
                  className={styles.heading}
                >
                  Recent Winners
                </p>
                <LiveFeedComponent
                  label={"Coming soon ..."}
                  time=""
                  prize=""
                />
              </div>
              {/* )} */}
              <div className={styles.prizesCont}>
                <div className={styles.header}>
                  <input
                    className={styles.prizeSearch}
                    placeholder="&#xF002; Search ..."
                    type={"text"}
                    onChange={(e) => {
                      setSearch(e.target.value.toLowerCase());
                    }}
                    style={{ fontFamily: "Itim, FontAwesome" }}
                  />
                  {/* {isLaptop && (
                <div className={styles.filterIconCont}>
                  <FilterAltIcon
                    onClick={() => setShowFilters((prev) => !prev)}
                    style={{ color: "white" }}
                  />
                  {isLaptop && !isMobile && <p>Filters</p>}
                </div>
              )} */}
                  {/* {(!isLaptop || showFilters) && ( */}
                  <div className={styles.filters}>
                    <select
                      className={styles.selector}
                      onChange={(e) => {
                        setFilters({ ...filters, category: e.target.value })
                        setskip(0)
                      }
                      }
                      defaultValue={"All"}
                    >
                      <option value={"All"}>All</option>
                      <option value={"Cards"}>Nit Cards</option>
                      <option value={"WL"}>WL</option>
                      <option value={"NFT"}>NFT</option>
                      <option value={"IRL Gift"}>IRL Gift</option>
                      <option value={"Web2 Gift"}>Web2 Gift</option>
                    </select>
                    <label>
                      <Checkbox
                        sx={{
                          color: "#d0484a",
                          "&.Mui-checked": {
                            color: "#d0484a",
                          },
                        }}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            newestFirst: e.target.checked,
                          })
                        }
                      />
                      Newest first
                    </label>
                    <label>
                      <Checkbox
                        sx={{
                          color: "#d0484a",
                          "&.Mui-checked": {
                            color: "#d0484a",
                          },
                        }}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            available: e.target.checked,
                          })
                        }
                      />
                      Available
                    </label>
                    <label>
                      <Checkbox
                        sx={{
                          color: "#d0484a",
                          "&.Mui-checked": {
                            color: "#d0484a",
                          },
                        }}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            includeExpired: e.target.checked,
                          })
                        }
                      />
                      Expired
                    </label>
                    <label>
                      <select
                        className={styles.selector}
                        onChange={(e) =>
                          setFilters({ ...filters, length: e.target.value })
                        }
                      >
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                      </select>
                      Items per page
                    </label>
                  </div>
                  {/* )} */}
                </div>
                <div className={styles.prizeCardsCont}>
                  {loading ? (
                    <button
                      className={`${styles.loader} ${styles.box} ${styles.button}`}
                    ></button>
                  ) : (
                    <>
                      <div className={styles.prizeCardGrid}>
                        {poolItems.length > 0 && poolItems
                          ?.slice(skip, parseInt(filters.length) + skip)
                          .map((item, key) => {
                            return (
                              <PrizeCard
                                data={item}
                                displayImg={item?.imageUrl}
                                title={item?.title}
                                setShowCardModal={setShowCardModal}
                                setdataItem={setdataItem}
                              />
                            );
                          })}
                      </div>
                    </>
                  )}
                  {showCardModal && dataItem && (
                    <Modal
                      open={showCardModal}
                      BackdropProps={{ style: style }}
                      onClose={() => setShowCardModal(false)}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        className={`${stylus.cardModal} ${stylus[classes?.BgClass]
                          }`}
                      >
                        <div className={stylus.cardImgCont}>
                          <img
                            src={dataItem.imageUrl}
                            className={stylus.cardImg}
                          />
                        </div>
                        <div className={stylus.cardDes}>
                          <h1 className={stylus.cardHeading}>
                            {dataItem.title}
                          </h1>
                          <p className={stylus.cardInfo}>
                            {dataItem.description}
                          </p>
                          {
                            <p className={stylus.cardInfo}>
                              Category: {dataItem.category}
                            </p>
                          }
                          {
                            <p className={stylus.cardInfo}>
                              Item Type: {dataItem?.onChainType}
                            </p>
                          }
                          {
                            <p
                              style={{ wordBreak: "break-all" }}
                              className={stylus.cardInfo}
                            >
                              Link:{" "}
                              <a
                                target="_blank"
                                style={{ color: "white" }}
                                href={dataItem.openSeaLink} rel="noreferrer"
                              >
                                {dataItem.openSeaLink}
                              </a>
                            </p>
                          }
                          {
                            <p className={stylus.cardInfo}>
                              Initial Supply: {dataItem?.initialSupplyInPool}
                            </p>
                          }
                          {
                            <p className={stylus.cardInfo}>
                              Supply Left: {dataItem?.onChainData?.supply}
                            </p>
                          }
                        </div>
                      </div>
                    </Modal>
                  )}
                  {poolItems && poolItems.length > 0 && (poolItems.length / parseInt(filters.length)) > 1 && (<Stack style={loading ? { display: 'none' } : {}} spacing={2}>
                    <Pagination
                      style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "10px" }}
                      count={Math.ceil(poolItems.length / parseInt(filters.length))}
                      color="primary"
                      onChange={(event, value) => {
                        setskip((value - 1) * 20);
                      }}
                    />
                  </Stack>)}
                </div>
              </div>
            </div>
            <MysteryBoxFooter />
          </div>
          <ConnectModal
            connectLoading={connectLoading}
            openModal={openConnectModal}
            setOpenModal={setOpenConnectModal}
            connectWallet={connectWallet}
            alertText={"Please connect to wallet first !"}
          />
          <ChangeChainModal
            openModal={changeChainModal}
            setOpenModal={setChainChangeModal}
            alertText={"Please Connect to Polygon Network !"}
          />
        </>
      )}
    </div>
  );
};

export default MysteryBox;
