// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_main__FV28D {\n  padding: 0% 5%;\n  background-repeat: no-repeat, no-repeat;\n  background: url(\"https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1667765555/backgroundImg.webp\") no-repeat center, rgba(19, 5, 10, 0.8);\n  background-blend-mode: darken;\n  background-size: cover;\n  height: 100vh;\n  width: 100%; }\n\n@media (max-width: 420px) {\n  .Layout_main__FV28D {\n    padding: 0px; } }\n", "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAc;EACd,uCAAuC;EACvC,sLACsB;EACtB,6BAA6B;EAC7B,sBAAsB;EACtB,aAAa;EACb,WAAW,EAAA;;AAGb;EACE;IACE,YAAY,EAAA,EACb","sourcesContent":["$assetPath: \"/assets/Layout\";\n\n.main {\n  padding: 0% 5%;\n  background-repeat: no-repeat, no-repeat;\n  background: url('https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1667765555/backgroundImg.webp') no-repeat center,\n    rgba($color: #13050a, $alpha: 0.8);\n  background-blend-mode: darken;\n  background-size: cover;\n  height: 100vh;\n  width: 100%;\n}\n\n@media (max-width:420px){\n  .main{\n    padding: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Layout_main__FV28D"
};
export default ___CSS_LOADER_EXPORT___;
