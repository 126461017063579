// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GiftShop_wrapper__c0IQ0 {\n  display: flex;\n  gap: 74px; }\n\n@media (max-width: 420px) {\n  .GiftShop_wrapper__c0IQ0 {\n    display: flex;\n    flex-direction: column;\n    position: relative; } }\n", "",{"version":3,"sources":["webpack://./src/pages/GiftShop/GiftShop.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS,EAAA;;AAGX;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB,EAAA,EACnB","sourcesContent":[".wrapper{\n  display: flex;\n  gap: 74px;\n}\n\n@media (max-width:420px){\n  .wrapper{\n    display: flex;\n    flex-direction: column;\n    position: relative;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "GiftShop_wrapper__c0IQ0"
};
export default ___CSS_LOADER_EXPORT___;
