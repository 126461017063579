import { Button, Modal } from "@mui/material";
import styles from "./CardModal.module.scss";
import ticketIcon from "../../../assets/Cards/ticket.svg";
import coinIcon from "../../../assets/Cards/coin.svg";
import fire from "../../../assets/Cards/fire.svg";
import TimerBar from "../../TimerBar";
import modalBg from "../../../assets/Cards/modalBg.svg";
import Moment from "react-moment";
import { useContext, useState } from "react";
import { WalletConnectContext } from "../../../hooks/walletLogin";
import { useCountdown } from "../../../hooks/countdown";

const dashboardAddOns = [
  {
    id: 0,
    label: "Expires in",
    action: "timer",
  },
  {
    id: 1,
    label: "Purchase Date",
    action: "27 OCT 22",
  },
  {
    id: 2,
    label: "Price",
    action: "price",
  },
];

const CardModal = ({
  data,
  displayImg,
  classes,
  transactionType,
  timer,
  ...props
}) => {
  const [days, hours, minutes, seconds] = useCountdown(timer);
  const timeSum = days + hours + minutes + seconds;

  const { userInfo, isWalletLogin, participateEvent } =
    useContext(WalletConnectContext);

  const { modalVisible, setModalVisible, listFor } = props;
  const [transactionCompleted, setTransactionCompleted] = useState(false);

  const participateNow = async () => {
    if (isWalletLogin) {
      let cnt = 1;
      await participateEvent(
        data._id,
        data.nftImage,
        data.name,
        data.nftType,
        data.price,
        userInfo.discordInfo?.username +
          "#" +
          userInfo.discordInfo?.discriminator,
        userInfo.discordInfo?.roles,
        cnt,
        setTransactionCompleted
      );
      // await fetchNfts()
      // await getActivity()
      return;
    } else {
      // toggleDrawer('', 'right', false)
      // setOpenConnectModal(true)
      // setParticipatingModal(false)
      return;
    }
  };
  const transactionIcons = {
    ticket: ticketIcon,
    coin: coinIcon,
  };

  const style = {
    background: `url(${modalBg}),rgba(7,2,4,0.8)`,
    backgroundRepeat: "no-repeat",
    filter: "blur(15px)",
    width: "100vw",
    height: "100vh",
  };

  return (
    <Modal
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      BackdropProps={{ style: style }}
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className={`${styles.cardModal} ${styles[classes?.BgClass]}`}>
        <div className={styles.cardImgCont}>
          <img src={displayImg} alt={data?.type} className={styles.cardImg} />
          {timer && listFor !== "dashboard" && (
            <span className={styles.timer}>
              <TimerBar
                type={"raffle"}
                timer={data?.timer}
                styles={{ borderRadius: "0px 0px 8px 8px" }}
              />
            </span>
          )}
        </div>
        <div className={styles.cardDes}>
          <h1 className={styles.cardHeading}>{data?.title}</h1>
          <p className={styles.cardInfo}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            varius sit amet quam sodales egestas. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit
          </p>
          {data?.discordRoles && (
            <div
              className={`${styles.discordRoles}  ${styles[classes?.DcRoles]}`}
            >
              <p className={styles.rolesHeading}>Allowed roles</p>
              <div className={styles.rolesCont}>
                {data?.discordRoles.map((item) => (
                  <div className={styles.roles}>{item}</div>
                ))}
              </div>
            </div>
          )}
          {listFor == "dashboard" ? (
            <>
              <div className={styles.addOnsWrapper}>
                {dashboardAddOns.map((item) => {
                  return (
                    <div className={styles.addOnsCont}>
                      <p className={styles.addOnsLabel}>{item.label}</p>
                      {item.id == 0 ? (
                        <div className={styles.expiresIn}>
                          <img src={fire} />
                          <p>
                            {data?.[item.action] &&
                            data?.[item.action] !== " " &&
                            timeSum > 0
                              ? `${days}:${hours}:${minutes}:${seconds}`
                              : "EXPIRED"}
                          </p>
                        </div>
                      ) : (
                        <p className={styles.addOnsAction}>
                          {item.id == 1 ? item.action : data?.[item.action]}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={styles.claimTransferBtCont}>
                <Button className={styles.claimButton}>Claim</Button>
                <Button className={styles.transferButton}>Transfer</Button>
              </div>
            </>
          ) : (
            <Button
              className={`${styles.cardButton} ${styles[classes?.BtClass]}`}
              onClick={participateNow}
            >
              <img
                src={transactionIcons[transactionType]}
                alt={transactionType}
              />
              {data?.price}
              {transactionType == "coin" ? " YDP" : " RAFFLE TICKETS"}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CardModal;
