import { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styles from './Header.module.scss'
import { Alert, Button, Drawer, Snackbar, useMediaQuery } from '@mui/material'
import searchIcon from '../../assets/Header/search.svg'
import walletIcon from '../../assets/Header/walletIcon.png'
import walletClip from '../../assets/Header/clip.svg'
import ticket from '../../assets/Cards/ticket.svg'
import coin from '../../assets/Cards/coin.svg'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import ConnectModal from '../Modals/ConnectModal'
import ChangeChainModal from '../Modals/ChangeChainModal'
import { WalletConnectContext } from '../../hooks/walletLogin'
import SideHeader from './SideHeader'
import { Close } from '@mui/icons-material'

const Header = ({ handleMenuItemSelect, menuItemSelected }) => {
    const {
        isWalletLogin,
        connectWalletThroughMetamask,
        connectWalletThrougConnectWallet,
        connectLoading,
        openModal,
        setOpenModal,
        tokenAmount,
        walletAddress,
        tokenBalance,
        boxBalance
    } = useContext(WalletConnectContext)

    const [openConnectModal, setOpenConnectModal] = useState(false)
    const [changeChainModal, setChainChangeModal] = useState(false)

    const [polygonChain, setpolygonChain] = useState(window?.ethereum?.networkVersion == process.env.REACT_APP_CHAIN_ID)
    const { ethereum } = window

    if (window.ethereum) {
        window.ethereum.on('chainChanged', (chain) => {
            if (parseInt(chain) == process.env.REACT_APP_CHAIN_ID) {
                setpolygonChain(true)
            } else {
                setpolygonChain(false)
            }
        });
    }

    const connectWallet = async (wallet) => {
        if (wallet == "metamask") {
            await connectWalletThroughMetamask(setOpenConnectModal, setChainChangeModal);
        } else if (wallet == "connectWallet") {
            await connectWalletThrougConnectWallet(setOpenConnectModal, setChainChangeModal);
        }
        return;
    };

    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width: 800px)')
    const [menuClicked, setMenuClicked] = useState(false)
    const [haveMetamask, sethaveMetamask] = useState(true)
    const [metamaskAlert, setMetamaskAlert] = useState(false)
    const [showSearch, setShowSearch] = useState(false)

    function handleClose() {
        setMetamaskAlert(false)
    }

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    return (
        <div className={styles.header}>
            <Snackbar
                open={metamaskAlert}
                autoHideDuration={6000}
                onClose={handleClose}
                message={
                    !haveMetamask
                        ? 'Metamask not found'
                        : isWalletLogin
                            ? 'Metamask successfully connected'
                            : ''
                }
            />
            <div className={styles.logoCont}>
                <img
                    src='https://9tales.io/wp-content/uploads/2022/05/nine-tales-logo.svg'
                    className={styles.logo}
                    alt='logo'
                />
            </div>
            {isMobile ? (
                <div className={styles.mobileOperations}>
                    <div className={styles.searchCont}>
                        {showSearch && <input className={styles.searchInput} />}
                        <img
                            src={searchIcon}
                            alt='search'
                            onClick={() => setShowSearch((prev) => !prev)}
                        // className={styles.searchIcon}
                        />
                    </div>
                    {isWalletLogin ? (
                        <div className={styles.walletCont}>
                            {/* <p className={styles.walletAddress}>{walletAddress}</p> */}
                            <div style={{
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '5px',
                                fontSize: '20px',
                                border: '2px solid #FF1840',
                                borderRadius: '10px'
                            }} className={styles.wallet}>
                                <img
                                    style={{ border: 'none', borderRadius: '8px' }}
                                    src={walletIcon}
                                    alt="wallet"
                                    className={styles.walletIcon}
                                />
                                {(polygonChain) && <img
                                    src={walletClip}
                                    alt="clip"
                                    className={styles.walletClip}
                                />}
                            </div>
                        </div>
                    ) : (
                        <Button
                            style={{
                                fontFamily: "Karantina",
                                fontSize: '20px',
                                border: 'white 2px solid',
                                borderRadius: '20px'
                            }}
                            className={styles.Button}
                            onClick={() =>
                                setOpenConnectModal(true)
                            }
                        >
                            Connect wallet
                        </Button>
                    )}
                    <div
                        className={styles.ButtonHamburger}
                        onClick={toggleDrawer('right', true)}
                    >
                        <MenuIcon style={{ color: '#FF1840' }} />
                    </div>
                </div>
            ) : (
                ''
            )}
            {(!isMobile || menuClicked) && (
                <>
                    <div className={styles.routes}>
                        <NavLink
                            to='/giftshop'
                            style={({ isActive }) =>
                                isActive
                                    ? { color: '#FF1840' }
                                    : { color: 'white' }
                            }
                            className={styles.navlinks}
                        >
                            Gift Shop
                        </NavLink>
                        <NavLink
                            to='/'
                            end
                            style={({ isActive }) =>
                                isActive
                                    ? { color: '#FF1840' }
                                    : { color: 'white' }
                            }
                            className={styles.navlinks}
                        >
                            Mystery Boxes
                        </NavLink>
                        {isWalletLogin && (
                            <NavLink
                                to='/dashboard'
                                style={({ isActive }) =>
                                    isActive
                                        ? { color: '#FF1840' }
                                        : { color: 'white' }
                                }
                                className={styles.navlinks}
                            >
                                Dashboard
                            </NavLink>
                        )}
                    </div>
                    <div className={styles.operations}>
                        {!isMobile && (
                            <div className={styles.searchCont}>
                                {showSearch ? (
                                    <div className={styles.searchActive}>
                                        <input className={styles.searchInput} />
                                        <div
                                            className={styles.closeIconCont}
                                            onClick={() => setShowSearch(false)}
                                        >
                                            <CloseIcon />
                                        </div>
                                    </div>
                                ) : (
                                    <img
                                        src={searchIcon}
                                        onClick={() => setShowSearch(true)}
                                        alt='search'
                                    />
                                )}
                            </div>
                        )}
                        {!showSearch && (
                            <>
                                {isWalletLogin ? (
                                    <>
                                        <div style={{ padding: '5px 16px 5px 12px' }} className={styles.assetContainer}>
                                            <img style={{ width: '35px' }} src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_200/v1667763115/chest_no_aura.webp'} alt="tickets" />
                                            <p className={styles.assetValue}>{boxBalance}</p>
                                        </div>
                                        <div
                                            className={styles.assetContainer}
                                        >
                                            <img src={coin} alt='coins' />
                                            <p className={styles.assetValue}>
                                                {tokenBalance}
                                            </p>
                                        </div>
                                    </>
                                ) : null}
                                {isWalletLogin ? (
                                    <div className={styles.walletCont}>
                                        {/* <p className={styles.walletAddress}>{walletAddress}</p> */}
                                        <div style={{
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '5px',
                                            fontSize: '20px',
                                            border: '2px solid white',
                                            borderRadius: '10px',
                                            paddingLeft: '4px'
                                        }} className={styles.wallet}>
                                            <p style={{ fontSize: '28px', paddingLeft: '6px', paddingBottom: '2px', fontFamily: "Karantina", margin: 0 }}>{`${walletAddress.toLowerCase().substring(0, 3)}...${walletAddress.toLowerCase().substring(
                                                walletAddress.length - 3,
                                                walletAddress.length
                                            )}`}</p>
                                            <img
                                                style={{ border: 'none' }}
                                                src={walletIcon}
                                                alt="wallet"
                                                className={styles.walletIcon}
                                            />
                                            {(polygonChain) && <img
                                                src={walletClip}
                                                alt="clip"
                                                className={styles.walletClip}
                                            />}
                                        </div>
                                    </div>
                                ) : (
                                    <Button
                                        style={{
                                            fontFamily: "Karantina",
                                            fontSize: '20px',
                                            border: 'white 2px solid',
                                            borderRadius: '20px'
                                        }}
                                        className={styles.Button}
                                        onClick={() =>
                                            setOpenConnectModal(true)
                                        }
                                    >
                                        Connect wallet
                                    </Button>
                                )}
                                {isMobile && (
                                    <CloseIcon
                                        onClick={() => setMenuClicked(false)}
                                        style={{ color: 'white' }}
                                    />
                                )}
                            </>
                        )}
                        <ConnectModal
                            connectLoading={connectLoading}
                            openModal={openConnectModal}
                            setOpenModal={setOpenConnectModal}
                            connectWallet={connectWallet}
                        />

                        <ChangeChainModal
                            openModal={changeChainModal}
                            setOpenModal={setChainChangeModal}
                            alertText={"Please Connect to Polygon Network !"}
                        />

                    </div>
                </>
            )}

            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                className={styles.drawer}
                hideBackdrop
                PaperProps={{
                    sx: {
                        background:
                            'linear-gradient(180deg, rgba(255, 255, 255, 0.01) 0%, rgba(173, 0, 0, 0.1) 100%)',
                        backdropFilter: 'blur(22.5px)',
                        borderRadius: '8px !important',
                        boxShadow: '0px 4px 24px 1px rgba(35, 16, 94, 0.25)',
                        color: 'white',
                        top: '20px',
                        right: '20px',
                        width: '250px',
                        height: 'calc(100% - 40px)',
                        border: '2px solid #FF184033',
                        overflow: 'hidden',

                        padding: '20px 0',
                    },
                }}
            >
                <div className={styles.drawer_container}>
                    <button
                        className={styles.close_drawer}
                        onClick={() => {
                            toggleDrawer('right', false)
                            setState({ ...state, right: false })
                        }}
                    >
                        <Close />
                    </button>

                    <NavLink
                        to='/giftshop'
                        style={({ isActive }) =>
                            isActive ? { color: '#FF1840' } : { color: 'white' }
                        }
                        className={styles.link_open}
                    >
                        gift shop
                    </NavLink>

                    <div className={styles.dashboard_links}>
                        <div className={styles.link_open_das}>dashboard</div>
                        <div className={styles.dashboard_links_container}>
                            <div
                                style={
                                    menuItemSelected === 'my_items'
                                        ? { color: '#FF1840' }
                                        : { color: 'white' }
                                }
                                className={styles.dash_link}
                            // onClick={() => handleMenuItemSelect('my_items')}
                            >
                                My Items
                            </div>
                            <div
                                className={styles.dash_link}
                                style={
                                    menuItemSelected === 'coin_collect'
                                        ? { color: '#FF1840' }
                                        : { color: 'white' }
                                }
                                onClick={() =>
                                    handleMenuItemSelect('coin_collect')
                                }
                            >
                                Coin Collect
                            </div>
                            <div
                                className={styles.dash_link}
                                style={
                                    menuItemSelected === 'history'
                                        ? { color: '#FF1840' }
                                        : { color: 'white' }
                                }
                            // onClick={() => handleMenuItemSelect('history')}
                            >
                                History
                            </div>
                        </div>
                    </div>

                    <NavLink
                        to='/'
                        style={({ isActive }) =>
                            isActive ? { color: '#FF1840' } : { color: 'white' }
                        }
                        className={styles.link_open}
                    >
                        game
                    </NavLink>
                </div>
            </Drawer>
        </div>
    )
}

export default Header
