import { useState } from "react";
import CardModal from "../CardModal";
import styles from "./PrizeCard.module.scss";

const PrizeCard = ({ data, displayImg, title, setShowCardModal, setdataItem }) => {
  return (
    <>
      <div className={styles.prizeCard} onClick={() => {
        setShowCardModal(true)
        setdataItem(data)
      }}>
        <img src={displayImg} alt="card Img" className={styles.displayImg} />
        {/* <p className={styles.title}>{title}</p> */}
      </div>
    </>
  );
};

export default PrizeCard;
