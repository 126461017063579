import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import GiftShop from "./pages/GiftShop";
import MysteryBox from "./pages/MysteryBox";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/giftshop" element={<GiftShop />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<MysteryBox />} />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    temp: state.temp,
  };
};

export default connect(mapStateToProps)(App);
