import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./MysteryBoxHeader.module.scss";
import walletIcon from "../../assets/Header/walletIcon.png";
import walletClip from "../../assets/Header/clip.svg";
import { Button, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { WalletConnectContext } from "../../hooks/walletLogin";
import ConnectModal from "../Modals/ConnectModal";
import ChangeChainModal from '../Modals/ChangeChainModal';
import ticket from "../../assets/Cards/ticket.svg";
import coin from "../../assets/Cards/coin.svg";

const MysteryBoxHeader = () => {
  const {
    isWalletLogin,
    connectWalletThroughMetamask,
    connectWalletThrougConnectWallet,
    connectLoading,
    walletAddress,
    tokenBalance,
    boxBalance
  } = useContext(WalletConnectContext);

  if (window.ethereum) {
    window.ethereum.on('chainChanged', (chain) => {
      if (parseInt(chain) == process.env.REACT_APP_CHAIN_ID) {
        setpolygonChain(true)
      } else {
        setpolygonChain(false)
      }
    });
  }

  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [changeChainModal, setChainChangeModal] = useState(false)

  const [polygonChain, setpolygonChain] = useState(window?.ethereum?.networkVersion == process.env.REACT_APP_CHAIN_ID);
  const [openCoinsModal, setOpenCoinsModal] = useState(false);

  const connectWallet = async (wallet) => {
    if (wallet == "metamask") {
      await connectWalletThroughMetamask(setOpenConnectModal, setChainChangeModal);
    } else if (wallet == "connectWallet") {
      await connectWalletThrougConnectWallet(setOpenConnectModal, setChainChangeModal);
    }
    return;
  };

  const isMobile = useMediaQuery("(max-width: 420px)");
  const smallScreen = useMediaQuery("(max-width: 900px)");
  const [menuClicked, setMenuClicked] = useState(false);

  return (
    <div className={styles.mysteryBoxHeader}>
      <a target="_blank" href="https://9tales.io">
        <img
          src="https://9tales.io/wp-content/uploads/2022/05/nine-tales-logo.svg"
          className={styles.logo}
          alt="logo"
        />
      </a>
      {(!smallScreen || menuClicked) && (
        <>
          <div className={styles.routes}>
            {smallScreen && (
              <CloseIcon
                onClick={() => setMenuClicked(false)}
                style={{ color: "white" }}
                className={styles.closeIcon}
              />
            )}
            <div
            // href="https://9tales.io/alpha"
            // style={({ isActive }) =>
            //   isActive ? { color: "#FF1840" } : { color: "white" }
            // }

            >
              <a target="_blank" href="https://9tales.io/alpha" className={`${styles.navlinks} ${styles.playDawn}`}>
                PLAY DAWN
              </a>
            </div>
            <NavLink
              to="/giftShop"
              exact
              style={({ isActive }) =>
                isActive ? { color: "#FF1840" } : { color: "white" }
              }
              className={styles.navlinks}
            >
              Gift Shop
            </NavLink>
            <NavLink
              to="/"
              style={({ isActive }) =>
                isActive ? { color: "#FF1840" } : { color: "white" }
              }
              className={styles.navlinks}
            >
              Mystery Boxes
            </NavLink>
            {isWalletLogin && (
              <NavLink
                to="/dashboard"
                style={({ isActive }) =>
                  isActive ? { color: "#FF1840" } : { color: "white" }
                }
                className={styles.navlinks}
              >
                Dashboard
              </NavLink>
            )}
          </div>
          <div className={styles.operations}>
            <>
              {isWalletLogin && !smallScreen ? (
                <>
                  <div style={{ padding: '5px 16px 5px 12px', fontFamily: "Karantina" }} className={styles.assetContainer}>
                    <img style={{ width: '35px' }} src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_200/v1667763115/chest_no_aura.webp'} alt="tickets" />
                    <p className={styles.assetValue}>{boxBalance}</p>
                  </div>
                  <div
                    style={{ fontFamily: "Karantina" }}
                    className={styles.assetContainer}
                    onClick={() => setOpenCoinsModal(true)}
                  >
                    <img src={coin} alt="coins" />
                    <p className={styles.assetValue}>{tokenBalance}</p>
                  </div>
                </>
              ) : null}
              {isWalletLogin && !smallScreen ? (
                <div className={styles.walletCont}>
                  {/* <p className={styles.walletAddress}>{walletAddress}</p> */}
                  <div style={{
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',
                    fontSize: '20px',
                    border: '2px solid white',
                    borderRadius: '10px',
                    paddingLeft: '4px'
                  }} className={styles.wallet}>
                    <p style={{ fontSize: '28px', paddingLeft: '6px', paddingBottom: '2px' }}>{`${walletAddress.toLowerCase().substring(0, 3)}...${walletAddress.toLowerCase().substring(
                      walletAddress.length - 3,
                      walletAddress.length
                    )}`}</p>
                    <img
                      style={{ border: 'none' }}
                      src={walletIcon}
                      alt="wallet"
                      className={styles.walletIcon}
                    />
                    {(polygonChain) && <img
                      src={walletClip}
                      alt="clip"
                      className={styles.walletClip}
                    />}
                  </div>
                </div>
              ) : !smallScreen && (
                <Button
                  style={{
                    fontFamily: "Karantina",
                    fontSize: '20px',
                    border: 'white 2px solid',
                    borderRadius: '20px'
                  }}
                  className={styles.Button}
                  onClick={() => setOpenConnectModal(true)}
                >
                  Connect wallet
                </Button>
              )}
              {/* {isMobile && (
              <CloseIcon
                onClick={() => setMenuClicked(false)}
                style={{ color: "white" }}
              />
            )} */}
            </>
          </div>
        </>
      )}
      {smallScreen && <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        {isWalletLogin ? (
          <div className={styles.walletCont}>
            {/* <p className={styles.walletAddress}>{walletAddress}</p> */}
            <div style={{
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
              fontSize: '20px',
              border: '2px solid white',
              borderRadius: '10px',
              paddingLeft: '4px'
            }} className={styles.wallet}>
              <p>{`${walletAddress.toLowerCase().substring(0, 3)}...${walletAddress.toLowerCase().substring(
                walletAddress.length - 3,
                walletAddress.length
              )}`}</p>
              <img
                style={{ border: 'none' }}
                src={walletIcon}
                alt="wallet"
                className={styles.walletIcon}
              />
              {(polygonChain) && <img
                src={walletClip}
                alt="clip"
                className={styles.walletClip}
              />}
            </div>
            {/* <div style={{ padding: '5px 16px 5px 12px' }} className='assetContainer'>
                    <img style={{ width: '35px' }} src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_200/v1667763115/chest_no_aura.webp'} alt="tickets" />
                    <p className='assetValue'>{boxBalance}</p>
                  </div>
                  <div
                    className='assetContainer'
                    onClick={() => setOpenCoinsModal(true)}
                  >
                    <img src={coin} alt="coins" />
                    <p className='assetValue'>{tokenBalance}</p>
                  </div> */}
          </div>
        ) : (
          <Button
            style={{
              fontFamily: "Karantina",
              fontSize: '20px',
              color: 'white',
              border: 'white 2px solid',
              borderRadius: '20px'
            }}
            className={styles.Button}
            onClick={() => setOpenConnectModal(true)}
          >
            Connect wallet
          </Button>
        )}
        <MenuIcon
          onClick={() => setMenuClicked((prev) => !prev)}
          fontSize="medium"
          style={{ color: "white", fontSize: '40px' }}
        />
      </div>}
      <>
        <ConnectModal
          connectLoading={connectLoading}
          openModal={openConnectModal}
          setOpenModal={setOpenConnectModal}
          connectWallet={connectWallet}
        />
        <ChangeChainModal
          openModal={changeChainModal}
          setOpenModal={setChainChangeModal}
          alertText={"Please Connect to Polygon Network !"}
        />
      </>
    </div>
  );
};

export default MysteryBoxHeader;
