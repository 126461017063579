import { Fragment, useRef } from "react";
import metamask from "../../assets/Modals/metamask.png";
import walletConnect from "../../assets/Modals/wallet-connect.png";
import coinbaseWallet from "../../assets/Modals/coin-base.png";
import closeButton from '../../assets/closeButton.svg'
import { Button, Modal } from "@mui/material";
import styles from "./ConnectModal.module.scss";

const ConnectModal = ({
  openModal,
  setOpenModal,
  connectWallet,
  connectLoading,
  alertText,
}) => {
  const cancelButtonRef = useRef(null);
  const Wallets = [
    {
      type: "metamask",
      name: "Metamask",
      logo: metamask,
    },
    {
      type: "connectWallet",
      name: "WalletConnect",
      logo: walletConnect,
    },
    // {
    //   type: "coinbase",
    //   name: "Coinbase",
    //   logo: coinbaseWallet,
    // },
  ];

  const style = {
    background: "rgba(0,0,0,0.6)",
    width: "100vw",
    height: "100vh",
    backdropFilter: "blur(10px)",
  };

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      BackdropProps={{ style: style }}
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className={styles.buttonsCont}>
        <p className={styles.alertText}>{alertText ? alertText : "Connect your wallet !"}</p>
        {Wallets.map((item) => (
          <button
            className={styles.connectButton}
            disabled={connectLoading}
            onClick={() => connectWallet(item.type)}
          >
            <p className={styles.metamask}>{item.name}</p>
            <img src={item.logo} alt="" className={styles.metamaskLogo} />
          </button>
        ))}
        {/* <p className={`${styles.metamask} newModalClass`} style={{ flexDirection: 'column',
    padding: '40px',
    height: 'auto',
    width: 'auto',
  fontSize: '40px',
gap: '20px' }}>CHOOSE YOUR WALLET <img onClick={() => {setOpenModal(false)}} style={{
                    cursor: 'pointer',
    position: 'absolute',
    top: '15px',
    right: '15px'
                }} src={closeButton} />
                <div className='fdc' style={{ display: 'flex', gap: '20px' }}>
            {Wallets.map((item) => {
              if (item.name != 'Coinbase') {
                return (
                  <button
                  style={{ gap: '20px', background: 'linear-gradient(180deg, #F1F1F1 0%, #D2D2D2 20.83%, #DADADA 40.1%, #E3E3E3 67.71%, #E4E4E4 100%)',
                  boxShadow: '0px 17px 9px -9px rgba(0, 0, 0, 0.19), inset 0px -2px 3px rgba(0, 0, 0, 0.25)',
                  borderRadius: '10px', width: '250px',
                  padding: '20px 40px' }}
                    className={styles.connectButton}
                    disabled={connectLoading}
                    onClick={() => connectWallet(item.type)}
                  >
                    <p style={{ fontFamily: 'Karantina', fontSize: '32px', color: '#686868' }} className={styles.metamask}>{item.name}</p>
                    <img src={item.logo} alt="" className={styles.metamaskLogo} />
                  </button>
                )
              }
              })}
              </div>
        </p> */}
      </div>
    </Modal>
  );
};

export default ConnectModal;