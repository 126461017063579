// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimerBar_timerBar__nfOVP {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 4px 0px;\n  gap: 6px;\n  border-radius: 16px; }\n  .TimerBar_timerBar__nfOVP.TimerBar_timerBarR__s0l4E {\n    background: #0F182E; }\n  .TimerBar_timerBar__nfOVP.TimerBar_timerBarW__jT2xa {\n    background: #FFFFFF; }\n  .TimerBar_timerBar__nfOVP .TimerBar_timerValue__LsgMW {\n    margin: 0px;\n    font-weight: 800;\n    font-size: 12px;\n    line-height: 20px; }\n    .TimerBar_timerBar__nfOVP .TimerBar_timerValue__LsgMW.TimerBar_timerValueR__d1UOT {\n      color: #FFFFFF; }\n    .TimerBar_timerBar__nfOVP .TimerBar_timerValue__LsgMW.TimerBar_timerValueW__SVf\\+5 {\n      color: #0F182E; }\n", "",{"version":3,"sources":["webpack://./src/components/TimerBar/TimerBar.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,QAAQ;EACR,mBAAmB,EAAA;EANvB;IASQ,mBAAmB,EAAA;EAT3B;IAaQ,mBAAmB,EAAA;EAb3B;IAiBQ,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,iBAAiB,EAAA;IApBzB;MAuBY,cAAc,EAAA;IAvB1B;MA2BY,cAAc,EAAA","sourcesContent":[".timerBar {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 4px 0px;\n    gap: 6px;\n    border-radius: 16px;\n\n    &.timerBarR {\n        background: #0F182E;\n    }\n\n    &.timerBarW {\n        background: #FFFFFF;\n    }\n\n    .timerValue {\n        margin: 0px;\n        font-weight: 800;\n        font-size: 12px;\n        line-height: 20px;\n        \n        &.timerValueR {\n            color: #FFFFFF;\n        }\n\n        &.timerValueW {\n            color: #0F182E;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timerBar": "TimerBar_timerBar__nfOVP",
	"timerBarR": "TimerBar_timerBarR__s0l4E",
	"timerBarW": "TimerBar_timerBarW__jT2xa",
	"timerValue": "TimerBar_timerValue__LsgMW",
	"timerValueR": "TimerBar_timerValueR__d1UOT",
	"timerValueW": "TimerBar_timerValueW__SVf+5"
};
export default ___CSS_LOADER_EXPORT___;
