import React from 'react';
import styles from './TimerUnit.module.scss';

const TimerUnit = ({ name, value }) => {
    return (
        <div className={styles.timerUnitContainer}>
            <div className={styles.timerUnitValueContainer}>
                <p className={styles.timerUnitValue}>{value[0]}</p>
                <p className={styles.timerUnitValue}>{value[1]}</p>
            </div>
            <p className={styles.timerUnitName}>{name}</p>
        </div>
    )
}

export default TimerUnit;