import { Fragment, useRef, useState } from 'react'
import metamask from '../../assets/Modals/metamask.png'
import walletConnect from '../../assets/Modals/wallet-connect.png'
import coinbaseWallet from '../../assets/Modals/coin-base.png'
import closeButton from '../../assets/closeButton.svg'
import { Button, Modal } from '@mui/material'
import styles from './ConnectModal.module.scss'
import Web3 from 'web3'

const ChangeChainModal = ({ openModal, setOpenModal, alertText }) => {
    const cancelButtonRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const style = {
        background: 'rgba(0,0,0,0.6)',
        width: '100vw',
        height: '100vh',
        backdropFilter: 'blur(10px)',
    }

    const web3 = new Web3(Web3.givenProvider)

    return (
        <Modal
            open={openModal}
            onClose={() => {
                if (window?.ethereum?.networkVersion == process.env.REACT_APP_CHAIN_ID) setOpenModal(false);
            }}
            BackdropProps={{ style: style }}
            style={{ display: 'flex', alignItems: 'center' }}
        >
            <div className={styles.buttonsCont}>
                {alertText && <p className={styles.alertText}>{alertText}</p>}

                <button
                    className={styles.connectButton}
                    disabled={loading}
                    onClick={async () => {
                        try {
                            setLoading(true)

                            const address = await window.ethereum.request({
                                method: 'eth_requestAccounts',
                            })

                            await window.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: web3.utils.toHex(process.env.REACT_APP_CHAIN_ID) }],
                            })
                            setOpenModal(false)
                        } catch (err) {
                            setLoading(false)
                            console.log("mumbai Error Code:", err.code)
                            // This error code indicates that the chain has not been added to MetaMask
                            if (err.code === 4902 || err.code === 32603 || err.code === -32603 || err.message.inclues("Try adding the chain using")) {
                                await window.ethereum.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainName: (process.env.REACT_APP_CHAIN_ID == 137 ? 'Polygon Mainnet' : 'Mumbai Testnet'),
                                            chainId: web3.utils.toHex(process.env.REACT_APP_CHAIN_ID),
                                            nativeCurrency: {
                                                name: 'MATIC',
                                                decimals: 18,
                                                symbol: 'MATIC',
                                            },
                                            rpcUrls: [
                                                (process.env.REACT_APP_CHAIN_ID == 137 ? 'https://polygon-rpc.com/' : 'https://matic-mumbai.chainstacklabs.com'),
                                            ],
                                        },
                                    ],
                                })
                            }
                        }
                    }}
                >
                    Switch network
                </button>
                {/* <p style={{ fontSize: '28px' }} className={`${styles.metamask} newModalClass`}>PLEASE SWITCH TO POLYGON NETWORK <img onClick={() => { setOpenModal(false) }} style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '15px',
                    right: '15px'
                }} src={closeButton} /></p> */}
            </div>
        </Modal>
    )
}

export default ChangeChainModal
