import { WalletConnectContext } from '../../hooks/walletLogin'
import styles from './Menu.module.scss'

const menuItems = {
    giftShop: [
        {
            label: 'all items',
            action: 'all',
            default: true,
        },
        {
            label: 'mystery box',
            action: 'mystery',
        },
        {
            label: 'raffle',
            action: 'raffle',
        },
        {
            label: 'exclusive items',
            action: 'exclusive',
        },
        {
            label: 'whitelist spots',
            action: 'whitelist',
        },
        {
            label: 'web 2 gifts',
            action: 'web_gifts',
        },
        {
            label: 'others',
            action: 'others',
        },
    ],
    dashboard: [
        {
            label: 'Claim YDFA',
            action: 'coin_collect',
        },
        {
            label: 'Inventory',
            action: 'my_items',
        },
        {
            label: "History",
            action: "history",
        },
        {
            label: 'Bridge',
            action: 'polygon_bridging',
        },
        {
            label: "leveling",
            action: "leveling",
        },
        {
            label: "Fusion",
            action: "claimed_items",
        },
        {
            label: "Mentorships",
            action: "claimed_items",
        },
        {
            label: "On-chain 2FA",
            action: "claimed_items",
        },

    ]
}

const Menu = ({
    menuItemSelected,
    handleMenuItemSelect,
    menuFor,
}) => {
    return (
        <>
            <div className={styles.menu}>
                {menuItems[menuFor].map((item, key) => {
                    return (
                        <div
                            key={key}
                            className={
                                menuItemSelected === item.action
                                    ? `${styles.menu_items} ${styles.menu_item_selected}`
                                    : styles.menu_items
                            }
                            onClick={() => {
                                if (item.action == 'coin_collect') {
                                    handleMenuItemSelect(item.action)
                                }
                            }}
                        >
                            {item.label}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Menu
