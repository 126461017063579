import axios from "axios";
import { MB_API } from "../hooks/walletLogin";

const getPoolInfo = async (pool) => {
  try {
    const response = axios.post(`${MB_API}/mysteryBox/getPoolInfo`, { pool });
    return response;
  } catch (err) {
    console.log(err);
  }
};

const getItemInfo = async (pool, ref) => {
  try {
    const response = axios.get(`${MB_API}/mysteryBox/getItemInfo`, { ref });
    return response;
  } catch (err) {
    console.log(err);
  }
};

const getPoolItems = async (data) => {
  try {
    const response = axios.post(`${MB_API}/mysteryBox/getPoolItems`, data);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export {
  getItemInfo,
  getPoolInfo,
  getPoolItems
};
