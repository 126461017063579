import React, { useEffect, useState } from 'react'
import styles from './CoinCollect.module.scss'
import Circle_Carret_Right from '../../assets/Circle_Carret_Right.png'
import coin from '../../assets/CoinCollect/coin.svg'
import openBox from '../../assets/CoinCollect/openBox.svg'
import TimerUnit from '../TimerUnit'
import { Modal } from '@mui/material'
import { WalletConnectContext } from "../../hooks/walletLogin";
import { useContext } from "react";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import Web3 from "web3";
import moment from 'moment';
import ChangeChainModal from '../Modals/ChangeChainModal';

import ydfClaimerAbi from '../ydfClaimerAbi.json';

const GAME_API = process.env.REACT_APP_GAME_API;

const CoinCollect = () => {
    const [notPolygonChain, setNotPolygonChain] = useState(window?.ethereum?.networkVersion !== process.env.REACT_APP_CHAIN_ID);

    const [modalVisible, setModalVisible] = useState(false)
    const [days, setdays] = useState('00')
    const [hours, sethours] = useState('00')
    const [minutes, setminutes] = useState('00')
    const [seconds, setseconds] = useState('00')
    const smallScreen = useMediaQuery("(max-width: 500px)");

    const {
        walletAddress,
        tokenBalance,
        tokenBalanceFunc,
        boxBalanceFunc,
        offChainBalance,
        claimTime,
        available,
        getUserStats } = useContext(WalletConnectContext);

    if (window.ethereum) {
        window.ethereum.on('chainChanged', (chain) => {
            if (parseInt(chain) != process.env.REACT_APP_CHAIN_ID) {
                setNotPolygonChain(true)
            } else {
                setNotPolygonChain(false)
            }
        });
    }

    useEffect(() => {
        getUserStats();
        tokenBalanceFunc();
        boxBalanceFunc();

    }, [walletAddress, tokenBalance]);

    setTimeout(() => {
        const timer = claimTime - new Date().getTime()
        const remainingdays = Math.floor(timer / (1000 * 60 * 60 * 24))
        const remaininghours = Math.floor((timer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const remainingminutes = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60))
        const remainingseconds = Math.floor((timer % (1000 * 60)) / (1000))
        setdays(remainingdays.toString())
        sethours(remaininghours.toString())
        setminutes(remainingminutes.toString())
        setseconds(remainingseconds.toString())
    }, 1000)

    return (
        <>
            <ChangeChainModal
                openModal={notPolygonChain}
                setOpenModal={setNotPolygonChain}
                alertText={"Please Connect to Polygon Network !"}
            />
            {!modalVisible ? (
                <div className={styles.CoinCollectContainer}>
                    {/* <img
                        className={styles.closedBoxImg}
                        src={closedBox}
                        alt='closed-box'
                    />
                    <div className={styles.coinValueContainer}>
                        <img className={styles.coinImg} src={coin} alt='coin' />
                        <p className={styles.coinValue}>2700</p>
                    </div> */}

                    <div className={styles.balanceHolderContainer}>
                        <BalanceBrick
                            type={'Game'}
                            colorStyle={styles.coinValueHolder}
                            image={styles.image_holder_game}
                            // onChainBalance={onChainBalance}
                            offChainBalance={offChainBalance}
                        />

                        <img src={Circle_Carret_Right} alt='' />

                        <BalanceBrick
                            type={'Wallet'}
                            colorStyle={styles.walletValueHolder}
                            image={styles.image_holder_wallet}
                            // onChainBalance={onChainBalance}
                            offChainBalance={offChainBalance}
                        />
                    </div>

                    {available ? (
                        <button
                            onClick={() => setModalVisible(!modalVisible)}
                            className={styles.claimBtn}
                        >
                            CLAIM
                        </button>
                    ) : (
                        <div className={styles.timerBox}>
                            {claimTime > 0 ? (
                                <>
                                    <TimerUnit name='DAYS' value={days.length == 2 ? [days[0], days[1]] : [0, days[0]]} />
                                    <TimerUnit name='HOURS' value={hours.length == 2 ? [hours[0], hours[1]] : [0, hours[0]]} />
                                    <TimerUnit name='MINUTES' value={minutes.length == 2 ? [minutes[0], minutes[1]] : [0, minutes[0]]} />
                                    {!smallScreen && <TimerUnit name='SECONDS' value={seconds.length == 2 ? [seconds[0], seconds[1]] : [0, seconds[0]]} />}
                                </>
                            ) : (
                                <p className={styles.textB}> This wallet was never used to play the game</p>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <CoinCollectedModal
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    offChainBalance={offChainBalance}
                />
            )
            }
        </>
    )
}

const CoinCollectedModal = ({ modalVisible, setModalVisible, offChainBalance }) => {
    const [sigLoading, setSigLoading] = useState(false)
    const [sigError, setSigError] = useState(false)
    const [modalMsg, setModalMsg] = useState("Loading...")
    const [successfullClaim, setSuccessfullClaim] = useState(false)
    const [claimingAmount, setClaimingAmount] = useState(0)

    const { walletAddress, tokenBalanceFunc, setclaimTime, getUserStats } = useContext(WalletConnectContext);

    const claimYdfa = async () => {
        const Web3 = require('web3');
        const web3 = new Web3(Web3.givenProvider);

        const ydfClaimerContract = await new web3.eth.Contract(
            ydfClaimerAbi,
            process.env.REACT_APP_YDF_CLAIMER_ADDRESS,
        );

        setSigLoading(true);
        setModalMsg("Generating the signature... This could take up to 20 seconds")

        await axios.post(`${GAME_API}/ydfClaim/getYdfaSignature`, {
            address: walletAddress,
        }).then(async (res) => {
            if (res.data.status == 200) {
                // setSigReceived(true);
                setClaimingAmount(res.data.sigData.claimingAmount);
                setModalMsg("Submitting the transaction in your wallet...")
                await ydfClaimerContract.methods.claimYDF(res.data.sigData.claimingAmount, res.data.sigData.timestamp, res.data.sigData.signature).send({ from: walletAddress }).then(() => {
                    setSigLoading(false);
                    setModalMsg("Success !")
                    setSuccessfullClaim(true)
                    // console.log("done claiming YDFA")
                    setclaimTime(0)
                    tokenBalanceFunc();
                    getUserStats();

                }).catch((err) => {
                    setSigError(true)
                    setSigLoading(false);
                    if (
                        typeof err !== "undefined" &&
                        typeof err.message !== "undefined" &&
                        (err.message.includes("User denied") || err.message.includes("rejected"))
                    ) {
                        setModalMsg("You rejected the transaction !! Try again.");
                    } else {
                        setModalMsg("Sorry, something went wrong. Please try again later.");
                    };
                    console.log("Error with transaction: ", err)
                })
            } else {
                setSigLoading(false);
                setSigError(true)
                setModalMsg(res.data.msg + " !")
            }
        }).catch((err) => {
            console.log("Error while getting transaction from MB API:", err)
        });

    }

    useEffect(() => {
        claimYdfa()
    }, [walletAddress]);

    const style = {
        background: 'rgba(0,0,0,0.4)',
        width: '100vw',
        height: '100vh',
        backdropFilter: 'blur(10px)',
    }
    return (
        <Modal
            open={modalVisible}
            onClose={() => {

                setModalVisible(false);
                setSigLoading(false);
                // setSigReceived(false);
                setSigError(false);
                setModalMsg("Loading...")
                setSuccessfullClaim(false);
                tokenBalanceFunc();
            }}
            BackdropProps={{ style: style }}
            style={{ display: 'flex', alignItems: 'center' }}
        >
            <div className={styles.CoinCollectModalContainer}>
                {successfullClaim ? (
                    <>
                        <img src={openBox} alt='open box' />
                        <p className={styles.CoinCollectModalMsg}>
                            Claimed successfully!
                        </p>
                        <div className={styles.coinValueContainer}>
                            <img className={styles.coinImg} src={coin} alt='coin' />
                            <p className={styles.coinValue}>{claimingAmount}</p>
                        </div>
                    </>) : (
                    // <>
                    <div className={styles.myContainer}>
                        {sigLoading ? (
                            <div className={styles.loadingContainer}>
                                <button
                                    className={`${styles.loader} ${styles.box} ${styles.button}`}
                                ></button>
                            </div>
                        ) : (null)}
                        <p className={sigError ? styles.textError : styles.textB}> {modalMsg} </p>
                    </div>
                    // </>
                )}
            </div>
        </Modal >
    )
}

export default CoinCollect

const BalanceBrick = ({ type, colorStyle, image, offChainBalance, onChainBalance }) => {
    const {
        tokenBalance
    } = useContext(WalletConnectContext);


    return (
        <div className={styles.collectCoinBrickContainer}>
            <div className={image}>
                <h1 className={styles.collectCoinBrickHead}>
                    your <b>{type}</b> Balance
                </h1>
                <div className={colorStyle}>
                    <img className={styles.coinImg} src={coin} alt='coin' />
                    <p className={styles.coinValue}>{type == 'Wallet' ? tokenBalance : (offChainBalance || 0)}</p>
                </div>
            </div>
        </div>
    )
}